#classic-text{
    color: #d2b28d;
    font-family: 'Times New Roman', Times, serif;
}

#shift ul{
  padding-left: 0;
}

#picture-size{
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16/12;
    background-color: #d2b28d;
    border-color: white;
}

#location{
    color: #d2b28d;
    font-family: 'Times New Roman', Times, serif;

}

.sections{
    color: #d2b28d;
}

.listed-one{
  display: inline-block;
  padding-right: 2px;
  transition: 0.3s;
  font-family: 'Times New Roman', Times, serif;
}

.figure{
    transition: all 0.3s;
}

.figure:hover{
    transform: scale(1.03);
}

.listed-one:hover{
  transform: scale(1.03);
  text-shadow: 2px 2px 4px rgba(187, 154, 33, 0.7);
}

.listed-two{
  display: inline-block;
  padding-right: 2px;
  color: #b20000;
}
.listed-three{
  display: inline-block;
  padding-right: 2px;
  transition: 0.3s;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-size: large;
}
.listed-three:hover{
  transform: scale(1.03);
  text-shadow: 2px 2px 4px rgba(187, 154, 33, 0.7);
}
.listed-four{
  display: inline-block;
  padding-right: 2px;
  color: #b20000;

}
.listed-five{
  display: inline-block;
  padding-right: 2px;
  transition: 0.3s;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-size: large;
}
.listed-five:hover{
  transform: scale(1.03);
  text-shadow: 2px 2px 4px rgba(187, 154, 33, 0.7);
}
.listed-six{
  display: inline-block;
  padding-right: 2px;
  color: #b20000;

}
.listed-seven{
  display: inline-block;
  padding-right: 2px;
  transition: 0.3s;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-size: large;
}
.listed-seven:hover{
  transform: scale(1.03);
  text-shadow: 2px 2px 4px rgba(187, 154, 33, 0.7);
}
.listed-eight{
  display: inline-block;
  padding-right: 2px;
  color: #b20000;

}
.listed-nine{
  display: inline-block;
  transition: 0.3s;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  font-size: large;
}
.listed-nine:hover{
  transform: scale(1.03);
  text-shadow: 2px 2px 4px rgba(187, 154, 33, 0.7);
}

.sections{
    text-decoration: none;
}