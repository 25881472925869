body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f4f4;
}

/*HEADER*/

.navbar{
  background-color: #b2000099;
}

.goldbar {
  border: solid #d2b28d 3px;
  height: 0;
  width: 190px;
}

.custom-toggler.navbar-toggler {
  border-color: black;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(157, 0, 0, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-nav{
  font-family: 'Times New Roman', Times, serif;
}

.nav-link.under{
  padding-left: 10px;
}

.nav-link.under:hover{
  background-color: #d2b28d;
  color: white;
  font-weight: bolder;
  text-shadow: 2px 2px 4px rgba(187, 154, 33, 1.8);
}

#sub{
  color: white;
  text-shadow: 2px 2px 4px black;
}

#sub:hover{
  color: #d2b28d;
}

#words{
  color: white;
  text-shadow: 2px 2px 4px black;
  font-family: 'Times New Roman', Times, serif;
}
#logo-img{
  max-width: 160px;
}

/* FOOTER */

#line{
  color: white;
  border: none;
  height: 2px;
  background-color: white;
}

#top{
  background-color: #d2b28d;
}

#red{
  color: #b20000;
}

#reds{
  color: #b20000;
  font-family: 'Times New Roman', Times, serif;
  transition: all 0.3s;
}

#reds:hover{
  color: white;
}

#first{
  border-right: solid white 2px;
}
.foot{
  background-color: #b20000;
}

.hover {
  transition: all 0.3s;
}

.hover:hover {
  transform: scale(1.1);
}

.foot-hover{
  text-decoration: none;
  color: white;
  font-family: 'Times New Roman', Times, serif;
  transition: all 0.3s;
}

.foot-hover:hover{
  color: #d2b28d;
  transform: scale(1);
}

@media screen and (max-width: 600px){
  .move{
    display: flex;
    justify-content: center;
  }
  
}