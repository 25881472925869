#hero-section {
    background-image: url("../Gallery/Gallery\ Photos/Demo\ 13.webp");
    height: 80vh;
     margin: 0 0 0 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  
  
  .test {
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
  
  }
  
  .gallery-link{
      text-decoration: none;
      color: #b20000;
  }
  
  #banner {
      background-color: #d2b28d;
      font-family: 'Times New Roman', Times, serif;
  }
  
  #card{
      transition: all 0.3s;
      
  }
  
  #card:hover{
      transform: scale(1.03);
  }
  
  .testamonials {
      background-color: #b20000;
      font-family: 'Times New Roman', Times, serif;
  }
  
  .header{
      color: #b20000;
      font-family: 'Times New Roman', Times, serif;
  }
  
  .views{
      transition: all 0.3s;
  }
  
  .views:hover{
      transform: scale(1.03);
      color: #d2b28d;
  }
  
  #img{
      max-height: 341px;
  }
  
  #kid-picture{
      max-width: 507px;
  }
  
  #title{
      font-family: 'Times New Roman', Times, serif;
      
      color: white;
  }
  
  #body{
      background-color: #d2b28d;
      text-decoration: none;
  }
  
  #estimate-button{
      background-color: #d2b28d;
      color: white;
      border: none;
      border-radius: 10px;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      margin: 4px 2px;
      cursor: pointer;
      font-family: "Times New Roman", Times, serif;
      transition: all 0.3s;
  
  }
  
  #Testamonials{
      background-color: #b20000;
      font-family: 'Times New Roman', Times, serif;
  }
  
  #estimate-button:hover{
      background-color: #b20000;
      color: white;
      transform: scale(1.03);
  }
  
  #story{
      color: #d2b28d;
      font-family: 'Times New Roman', Times, serif;
  }
  
  #margin{
      margin-top: 100px;
  
  }
  
  @media screen and (max-width: 600px){
      #margin{
          margin-top: 0px;
      }
  }
  
  
  .bigger{
      font-size: 7vw;
      font-family: 'Times New Roman', Times, serif;
  }
  
  .smaller{
      font-size: 4vw;
      font-family: 'Times New Roman', Times, serif;
  }
  
  .our-products{
      color: #b20000;
      font-family: 'Times New Roman', Times, serif;
      margin-top: 20px;
  }