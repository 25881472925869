#services {
    background-color: #b20000;
     color: white;
     font-family: 'Times New Roman', Times, serif;
 }
 
 
 
 #sub-title{
     font-family: 'Times New Roman', Times, serif;
     color: #b20000
 }
 
 #service{
     color: #d2b282;
 }